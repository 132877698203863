import './src/css/global.css'


export const onClientEntry = () => {
/*
    const siteId = 4975986;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
*/
};
